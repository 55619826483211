import React from "react"
import Reveal, { Fade } from "react-awesome-reveal"
import styled from "styled-components"

import ResortItem from "./resortItem"
import revealAnimation from "../../../styles/animations/revealAnimation"

const Resort = ({ title, items }) => {
  return (
    <Container>
      <Reveal keyframes={revealAnimation} triggerOnce>
        <Title dangerouslySetInnerHTML={{ __html: title.html }} />
      </Reveal>
      <ItemsContainer>
        {items.map((item, i) => (
          <Fade key={i} delay={(i + 1) * 100} triggerOnce>
            <ResortItem {...item} />
          </Fade>
        ))}
      </ItemsContainer>
    </Container>
  )
}

export default Resort

const Container = styled.div`
  padding: 64px 0 40px 0;
  width: 90%;
  margin: 0 auto;
`
const Title = styled.div`
  h1 {
    color: var(--primary-text);
    font-size: 2rem;
  }
`
const ItemsContainer = styled.div`
  padding: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  gap: 30px;
  @media(max-width: 1140px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media(max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`
