import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import HomeAway from "../components/home/homeAway"
import Resort from "../components/home/resort"
import Gallery from "../components/home/gallery/gallery"
import Newsletter from "../components/home/newsletter"
import BookNowBanner from "../components/home/book"
import Fullscreen from "../components/home/fullscreen"

import { useHomeData } from "../hooks/useHomeData"
import Slider from "../components/home/slider"
import { withPreview } from "gatsby-source-prismic"

const componentMapper = {
  home_away_from_home: HomeAway,
  resort: Resort,
  gallery: Gallery,
  slider: Slider,
  newsletter: Newsletter,
  book: BookNowBanner,
  fullscreen: Fullscreen,
}
const IndexPage = ({ data: previewData }) => {
  const mergedData = useHomeData(previewData)
  const navigationPreview = mergedData.prismicNavigation
  const footerPreview = mergedData.prismicFooter
  const { uid, data } = mergedData.prismicHomePage

  let sections
  let bookData
  let fullScreenData

  sections = data.body.filter(section => section.slice_type !== "book" && section.slice_type !== "fullscreen")
  bookData = data.body.find(section => section.slice_type === "book").primary
  fullScreenData = data.body.find(section => section.slice_type === "fullscreen").primary

  return (
    <Layout
      bookBannerData={bookData}
	    fullScreenBannerData={fullScreenData}
      heroData={{ ...sections[0].primary }}
      footerPreviewData={footerPreview}
      navigationPreviewData={navigationPreview}
    >
      <SEO title={uid} videoUrl={sections[0].primary.video.url} />
      {sections.slice(1).map(section => {
        const Component = componentMapper[section.slice_type]
        return (
          <Component
            key={section.slice_type}
            {...section.primary}
            items={section.items}
          />
        )
      })}
    </Layout>
  )
}
export default withPreview(IndexPage)
